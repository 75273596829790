.applications {
  .section-top {
    display: flex;
    justify-content: space-between;
    .action-holder {
      button {
        &:first-child {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .title-head {
    font-size: 25px;
    font-family: 'Avenir-Medium', sans-serif !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      font-family: "Avenir-Medium", sans-serif !important;
      font-size: 15px;
    }
  }

  .ant-table-cell {
    // text-transform: capitalize;
    font-size: 16px;
  }
}