.homepage {
  .homepage-main {
    padding: 0rem 6rem;
    .ant-row{
      @include mobile() {
       flex-direction: column-reverse !important;
      }
    }
    @include mobile() {
      padding: 0rem 1rem;
    }

    .banner-content {
      padding-bottom: 2em;
      @include mobile() {
        text-align: center;
      }

      .title {
        font-size: 58px;
        margin-bottom: 1rem;
        color: $titlePurple;
        line-height: 73px;
        font-family: 'Avenir-Bold', sans-serif !important;
        padding: 3rem 0rem 0;
      @include mobile() {
        line-height: 40px;
        font-size: 2.5em;
        padding: 0.5em;
        margin-bottom: 0;
      }
      }

      .footnote {
        font-size: 1.2em;
        color: $fontgrey;
        margin-bottom: 1.5rem;
        font-family: 'Avenir-Medium', sans-serif !important;
      }

      .careers-btn {
        // border-radius: 40px;
        background: $purple;
        color: $white;
        padding: 0.5rem 3rem 2.2rem;
        height: 2.5rem;
        outline: none !important;
        border-color: $white !important;
        font-size: 1.2em;
        &:hover,
        &:focus,
        &:active {
          outline: none !important;
          border-color: $white !important;
        }
      }
    }

    .img-holder {
      img {
        width: 100%;
        // height: 35rem;

        @include mobile() {
          // height: 10rem;
        }
      }
    }
  }

  .carousel-initialized{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)) !important;
    img{
      width: 70%;
    z-index: -1;
    position: absolute;
      @include mobile() {
       width: 100%;
          }
          @include tablet() {
            width: 70%;
               }
    }
    
  }
  .carousel-track{
 
    z-index: -1;
  }
  .x-banner {
    height: 70vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.4)),
    //   url("https://res.cloudinary.com/afrikal/image/upload/v1614412010/bedroom_bydwyx.png")
    //     no-repeat;
   

    p {
      font-family: "Avenir-Medium", sans-serif !important;
      font-size: 1.2em;
      margin: 1em 0 2em;
      color: #fff;
      @include mobile() {
        font-size: 1.1em;
      }
    }
    @include mobile() {
    height: 50vh !important;
    }
  }
}