.inventory {
  .section-top {
    display: flex;
    justify-content: space-between;
    .action-holder {
      button {
        &:first-child {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .title-head {
    font-size: 1.5em;
    // font-family: "Avenir-Medium", sans-serif !important;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-family: "Avenir-Medium", sans-serif !important;
      font-size: 15px;
    }
  }

  .ant-table-cell {
    // text-transform: capitalize;
    font-size: 16px;
  }

  .product-details {
    padding-left: 3rem;

    .title {
      font-size: 15px;
      font-family: "Avenir-Medium", sans-serif !important;
    }
  }

  .action-holder {
    display: flex;
    align-items: center;

    .anticon {
      margin-right: 0.5rem;
      cursor: pointer;
    }
  }

  .viewentries-section {
    .section-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;

      h2 {
        margin-bottom: 0;
        a {
          text-transform: capitalize;
        }
      }

      .go-back {
        color: $black;
        font-family: "Avenir-Medium", sans-serif !important;
        cursor: pointer;
      }
    }
    .section-main {
      .alert-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      p {
        margin-bottom: 0;
        font-family: "Avenir-Medium", sans-serif !important;
        cursor: pointer;
        .action-btn {
          &.prev {
            margin-right: 1rem;
          }
          &.next {
            margin-left: 1rem;
          }
        }
      }
    }
    .section-bottom {
      .search-holder {
        margin-bottom: 0.5rem;
      }
    }
  }

  .show-calender {
    // .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    //   display: none;
    // }
    .section-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;

      h2 {
        margin-bottom: 0;
        a {
          text-transform: capitalize;
        }
      }

      .go-back {
        color: $black;
        font-family: "Avenir-Medium", sans-serif !important;
        cursor: pointer;
      }
    }
  }
}
