.footbody {
    display: flex;
    padding: 5.0625rem  5.875rem;
    justify-content: space-between;
    background-color: #052543;
    @include mobile() {
        display: grid;
        gap: 7rem;
        padding: 3em 2em;
        margin-top: 2em;
      
      }

     

    .linkparaf {
        display: flex;
        gap: 1.5rem;

        cursor: pointer;
        @include mobile(){
            flex-direction: column;
        }
        
    }

.quicklink {
    color: #D1D5DB;
    width: fit-content;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    font-feature-settings: 'liga' off;
    
    // @include mobile(){
    //     font-size: unset;
    // }
    
}

.copyright {
    color: #D1D5DB;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    position: absolute;
    width: fit-content;
    font-feature-settings: 'liga' off;
    margin-top: 1.1rem;
    @include mobile(){
        font-size: unset;
    }
}
    
    .social_span {
        width: 2.149375rem;
        height: 2.149375rem;
        background-color: #2072BF;
        border-radius: 50%;
        font-size: 3rem;
        margin-left: .3rem;
        @include mobile(){
           width: 1.5rem;
           height: 1.5rem;
        }
    }

    .footheader {
        color: #FFFFFF;
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 600;
        font-size: 1.625rem;
        line-height: 2.5625rem;
        width: fit-content;
        letter-spacing: -0.0114774375rem;

        @include mobile(){
            font-size: 1.5em;
        }
    }


    .f-span {
        width: 2.149375rem;
        height: 2.149375rem;
        background-color: #2072BF;
        border-radius: 50%;
        font-size: 3rem;
        // @include mobile(){
        //     font-size: unset;
        // }
    }
}

.contactSpanf {
    display: flex;
    color: #D1D5DB;
    gap: 1rem;
    align-items: center;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    // line-height: .1rem;
    // @include mobile(){
    //     font-size: unset;
    // }
    .contact {
        margin-top: .9rem;
        
    }
}
.contactSpan {
    display: flex;
    color: #D1D5DB;
    gap: 1rem;
    align-items: center;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: .1rem;
    margin-top: .9rem;
    
    .contact {
        margin-top: .9rem;
        
    }

}
