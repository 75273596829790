.x-resources{
    .section-top {
        display: flex;
        justify-content: space-between;
        .action-holder {
          button {
            &:first-child {
              margin-right: 0.5rem;
            }
          }
        }
      }
    
      .title-head {
        font-size: 25px;
        font-family: "Avenir-Medium", sans-serif !important;
        align-items: center;
        display: flex;
        button{
          margin-right: 1em;
        }
      }
    // h1{
    //   font-size: 1.5em;
    // }
    .x-box{
    box-shadow: $extrabox-shadow;
    background: $white;
    cursor: pointer;
    margin: 2em;
    padding: 3em;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
    flex-direction: column;
    }
}
.x-maintainance{
  .ant-picker {
    width: 100%;
    border-radius: 0px !important; 
}
  .section-top {
    display: flex;
    justify-content: space-between;
    .action-holder {
      button {
        &:first-child {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .title-head {
    font-size: 25px;
    font-family: "Avenir-Medium", sans-serif !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      font-family: "Avenir-Medium", sans-serif !important;
      font-size: 15px;
    }
  }

  .ant-table-cell {
    // text-transform: capitalize;
    font-size: 16px;
  }
  .tickets{
    margin-top: 2em;
      h3{
        font-size: 1em;
      }
      .details{
        // margin-top: 2em;
        margin-bottom: 2em;
        padding: 2em;
      }
      .footnote{
        margin-bottom: 2em;
      }
  }
}
.ant-select-multiple .ant-select-selection-item-content{
  div{
    padding: unset !important;
  }
}

.x-tracker{
  border-radius: 20px;
  border: 1px solid #000;
  // border-width: 5px;
  overflow: hidden;
  .x-wrapper{
    display: flex;

  .x-step{
    // width: 50px;
    height: 50px;
    border-right: 2px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    font-size: 0.9em;
    text-align: center;

    &.active{
      background: red;
      color: #fff;
    }
  }
  }
}