
.x-nav{
  background-color: $white;

  .logo {
    
      color: $fontgrey;
      // font-size: 30px;
      margin-bottom: 0;
      color: $titlePurple;
      font-size: 23px;
      padding: 1em 1.2em;
      span {
        font-family: 'Avenir-Bold', sans-serif !important;
      }

      @include mobile() {
        font-size: 23px;
      }
    
  }
  img{
    width: 100px;
    border-radius: 30%;
  }
}

.x-nav ul{
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  padding: 1.5em 4em;
  align-items: center;

button{
  background: $red !important;
  color: #fff !important;
  border: unset !important;
  border-top-right-radius: 10px !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
  @include mobile() {
    height: 35px !important;
    padding: unset !important;
    font-size: 1.1em !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
    }
}

  &.x-mobile{
    display: none;
  }
  @include mobile(){
    &.x-mobile{
      display: block;
    }
  
  padding: 1em 1.3em 1em 0
  }
}
.x-nav ul li:first-of-type{
  margin-right: auto;
  @include mobile(){
    display: block;
  }
}
.x-nav ul li:last-of-type{
  
}
.x-nav ul li{
  &.x-mobile{
    display: none;
  }
  @include mobile(){
    display: none;
    &.x-mobile{
      display: block;
    }
  }
}

.x-nav ul li a{
  padding: 2em;
  font-family: 'Avenir-Medium', sans-serif !important;
  color: #555;
  font-size: 1.2em;
}

.x-nav button{
  font-size: 1.2em;
  color: #555;
  // height: unset;
  font-family: 'Avenir-Medium', sans-serif !important;
  padding: 0.2em 1.5em;
  @include mobile() {
    font-size: 1em;
  }
}
