.jobs {
  .title-head {
    font-size: 25px;
    font-family: "Avenir-Medium", sans-serif !important;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-family: "Avenir-Medium", sans-serif !important;
      font-size: 15px;
    }
  }

  .ant-table-cell {
    // text-transform: capitalize;
    font-size: 16px;
  }

  .job-details {
    padding-left: 3rem;

    .title {
      font-size: 15px;
      font-family: "Avenir-Medium", sans-serif !important;
    }
  }

  .action-holder {
    display: flex;

    .anticon {
      margin-right: 0.5rem;
      cursor: pointer;
    }
  }

  .applications-section {
    .section-top  {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;

      h2 {
        margin-bottom: 0;
        a {
          text-transform: capitalize;
        }
      }

      .go-back {
        color: $black;
        font-family: "Avenir-Medium", sans-serif !important;
        cursor: pointer;
      }
    }
    .section-bottom {
      .search-holder {
        margin-bottom: 0.5rem;
      }
    }
  }
}
