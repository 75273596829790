.addjob {
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .go-back {
      color: $black;
      font-family: 'Avenir-Medium', sans-serif !important;
      cursor: pointer;
    }
  }

  .title-head {
    font-size: 25px;
    font-family: 'Avenir-Medium', sans-serif !important;
  }

  .ant-form-item-label {
    padding: 0;
  }

  input {
    height: 2.5rem;
    border-radius: 8px;
  }

  .ant-select-selector {
    height: 2.5rem !important;
    border-radius: 8px !important;
    padding: 0.2rem 11px !important;
  }

  textarea {
    border-radius: 8px;
    padding-top: 0.5rem;
  }

  .sections {
    margin-bottom: 1rem;

    .section-top {
      display: flex;
      justify-content: space-between;
    }

    .section-main {
      .section-slot {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        .closeIcon {
          margin-left: 1rem;
          cursor: pointer;
        }
      }
    }
  }

  .submit-btn {
    margin-right: 0.5rem;
  }
}