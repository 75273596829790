.navbar {

  // @include mobile() {
  //   display: grid;
  //   gap: -3rem;
  //   padding: 1rem 4rem 1rem 3rem;

  // }

  .navbar-container {
    display: flex;
    // height: 4.5rem;
    justify-content: space-between;
    align-items: center;
    padding: 1em 5em;
    @include mobile(){
      padding: 2em;
    }
  }

  .hamburgerN {
    display: none;
    .hamburger{
      border: unset;
    }
    @include mobile() {
      position: absolute;
      display: flex;
      top: 1.7rem;
      right: 1.5rem;
      z-index: 10;
    }

  }



  .list_style_sm{
    display: none;
    &.active{
      display: flex;  
    }
    @include mobile(){ 
     margin: 0;
     padding: 0 2em;
     flex-direction: column;
     list-style: none;
     .listholders{
      .listItem{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        margin-bottom: 1rem;
        display: block;
        color: #15518A;
      }
    }

    .butholders {
      background: #15518A;
      border-radius: .26618rem;
      border: none;

      @include mobile() {
        align-self: flex-start;
      }
      .listInBut {
        font-family: 'Inter';
        color: #FFFFFF;
        font-style: normal;
        font-weight: 600;
        opacity: .9;
}
}
  }
  }


  .navlogo {
    width: 8rem;
    height: 2.359375rem;

    @include mobile() {
      width: 5rem;
      height: 1.5rem;
    }
  }



  .list_style {
    width: fit-content;
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 1.5625rem;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;


    @include mobile() {

      // display: inline;
      // flex-wrap: wrap;
      // gap: 4rem;
      &.lg {
        display: none;
      }


      padding-left: 4.5rem;
      padding-top: 1.5rem;
      height: 14rem;
      margin-top: 19.3rem;
      width: 15rem;
      margin-right: -4.9rem;
      background-color: rgba(232, 235, 239, 0.5);
    }

    .listholders {
      width: fit-content;
    }

    .listItem {
      // color: #000000;
      width: fit-content;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 700;
      font-size: .975rem;
      line-height: 1.25rem;
      font-feature-settings: 'salt' on, 'liga' off;
    
      // &:focus {
      //   color: #15518A;
      // }
    
      &:hover {
        color: #15518A;
      }
    
      // &:active {
      //   color: #15518A;
      // }
    
      @include mobile() {
        margin-top: 1.5rem;
      }
    
    }
    
    .listItemH {
      width: fit-content;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 700;
      font-size: .975rem;
      line-height: 1.25rem;
      font-feature-settings: 'salt' on, 'liga' off;
      color: #000000;
    
      &:focus {
        color: #15518A;
      }
    
      &:hover {
        color: #15518A;
      }
    
      &:active {
        color: #15518A;
      }
    
      @include mobile() {
        margin-bottom: 1.5rem;
      }
    }


    .butholders {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: none;
      background: #15518A;
      border-radius: .26618rem;
      cursor: pointer;
    
      & :hover {
        opacity: .9;
    
      }
    
      .listInBut {
        padding: .38922375rem .794625rem;
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #FFFFFF;
    
      }
    }
  }
}




