.upload-invoices {
  .section-top {
    display: flex;
    justify-content: space-between;
    .action-holder {
      button {
        &:first-child {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .title-head {
    font-size: 25px;
    font-family: "Avenir-Medium", sans-serif !important;
    align-items: center;
    display: flex;
    button{
      margin-right: 1em;
    }
  }
  .submit-btn{
    margin-top: 2em;
    ;
  }
}