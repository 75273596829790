.locations {
  .section-top {
    display: flex;
    justify-content: space-between;
  }

  .title-head {
    font-size: 25px;
    font-family: "Avenir-Medium", sans-serif !important;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-family: "Avenir-Medium", sans-serif !important;
      font-size: 15px;
    }
  }

  .ant-table-cell {
    // text-transform: capitalize;
    font-size: 16px;
  }

  .action-holder {
    display: flex;
    align-items: center;

    .anticon {
      margin-right: 0.5rem;
      cursor: pointer;
    }
  }

}

.x-location{
  h1{
    font-size: 1.5em;
  }
  .x-box{
  box-shadow: $box-shadow;
  background: $white;
  cursor: pointer;
  margin: 2em;
  padding: 3em;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bold;
  flex-direction: column;
  }
}