.dashboard-content-cards {
  margin-bottom: 1rem;

  .flashcard {
    box-shadow: $box-shadow;
    border-radius: 7px;
    cursor: pointer;
    height: 10rem;

    @include mobile() {
      margin-bottom: 1rem;
    }

    &.antblue {
      background: $antblue;
    }

    &.warning {
      background: $warning;
    }

    &.success {
      background: $success;
    }

    &.danger {
      background: $danger;
    }

    .ant-card-body {
      padding: 0px;
    }

    .top {
      padding: 2rem 2rem 2.5rem;
      display: relative;
      color: $white;
      text-transform: capitalize;

      h3 {
        color: $white;
        font-family: 'Avenir-Medium', sans-serif !important;
        width: 50%;
      }
    }

    &-icon {
      position: absolute !important;
      z-index: 0;
      top: 0.9rem;
      right: 1rem;
      font-size: 5rem;
      -webkit-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
      transform: rotate(15deg) !important;
    }
  }
}