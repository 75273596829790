.navmodal {
    position: fixed;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    background-position: right;
    z-index: 2000;
    color: #f7f7f7;
    padding: 50px 0px;

    .x-close {
        padding: 0 3em;
        text-align: right;
    }
}
.modal-links {
    // text-align: center !important;
    margin-top: 3em;
    p {
        text-align: center;
    }
    a {
        font-size: 1.2em;
        color: #fff;
        display: block;
        margin-bottom: 2em;
    }
}
