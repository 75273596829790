.careerscard {
  border-radius: 8px;
  background: $white;
  box-shadow: $box-shadow;
  padding: 1.5rem 1.5rem 1.5rem 2rem;
  display: grid;
  grid-template-columns: 8% 1fr;
  grid-column-gap: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  @include mobile() {
    grid-template-columns: 100%;
  }

  .logo-holder {
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    justify-content: center;
    display: flex;
    align-items: center;
    background: $cardpurple;
    position: relative;
      color: $titlePurple;
      font-family: 'Avenir-Bold', sans-serif !important;

    @include mobile() {
      display: none;
    }

    img {
      width: 50%;
      height: 50%;
      margin: 0.75rem 0.9rem;
    }
  }

  .careerscard-details {
    .title {
      color: $black;
      font-size: 18px;
      font-weight: bolder;
      margin-bottom: 0;
      font-family: 'Avenir-Medium', sans-serif !important;
      text-transform: capitalize;
    }

    .footnote {
      // color: $titlePurple;
      font-weight: bolder;
      margin-bottom: 0em;
      font-family: 'Avenir-Medium', sans-serif !important;
      text-transform: capitalize;
    }

    .details {
      color: $fontgrey;
      text-transform: capitalize;
    }

    .action-holder {
      display: flex;
      @include mobile() {
        display: grid;
        grid-template-columns: 100%;
      }

      .action-show {
        background: $lighterwhite;
        border-radius: 8px;
        margin-right: 1rem;
        padding: 0.5rem 0.9rem;
        font-size: 0.9em;
        text-transform: capitalize;
        img {
          height: 0.8rem;
          margin-right: 0.5rem;
        }
      }
    }
  }

  .caret-holder {
    background: $lighterwhite;
    border-radius: 50%;
    padding: 0.6rem 0.7rem;
    height: 2.3rem;
    cursor: pointer;
    display: none;
  }
}