.main-holder, .about-nav, .a-header, .f-con, .s-con, .about-footer {
    margin:0;
    @include mobile() {
    padding:.5rem;
    text-align: center;
    margin-bottom: -.9rem;
        
    }

//     @include smallmobile2nd() {
//         margin-bottom: -.8rem;
       
//    }
}




.a-header {
    background: #ECF2FF;
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 57px;
    /* or 98% */
    padding: 3.9375rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #15518A;
    // padding: 0.0625rem 37.5rem;

    @include mobile() {
        font-size: 25px;
        
    }

}





.f-con {
    background: #15518A;
    padding: 5rem 7.625rem 6.3125rem 5.0625rem;

    @include mobile() {
        padding: 5rem 2rem;

    }
    
    
    .w-con {

        .wordings {

            .h1-top {
                margin-top: 1.5rem;
                font-family: 'Playfair Display';
                font-style: normal;
                font-weight: 700;
                font-size: 54px;
                color: #FFFFFF;

                @include mobile() {
                    font-size: 25px;
                    margin-top: -1.5rem;

                    
                }
    
            }
    
            .about-comp {
                margin-top: -2.2rem;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 22.231px;
                color: #FFFFFF;
                @include mobile() {
                margin-top: 1rem;
                font-size: 1.2em;
                }
            }
        }

    }

    .i-con {
        
        @include desktop2nd() {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        

         .image-shelf {
            @include mobile() {
                margin-right: 1.8rem ;
            }
                .fcon-image {
                    width:100%;
                    height:auto;
                    border: solid 0.5rem #7EC0FF;
                }

            
         }

        
    }
}








.s-con {
    background: #F6F9FF;
    // position: relative;
    // z-index: -1;
    padding: 5.5625rem 7.6875rem 5.5625rem 4.9875rem;

    @include mobile() {
        padding: 5rem 2rem;

    }
    // @include smallmobile3rd() {
    //     padding-top: 6.5rem;
    // }
//     @include smallmobile2nd() {
//         padding-bottom: 8rem;
       
//    }

    .woman_image {
          
        @include desktop2nd() {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .content-C {
            position: relative;
            z-index: 1;
            

            .woman_imager {
                margin-top: -23rem;
                margin-bottom: .5625rem;

                @include desktop2nd() {
                    margin-top: .4rem;
                }

              }

            .redder {
                position: relative;
                background-color: #E32E3F;
                width: 50%;
                height: auto;
                color: transparent;
                padding:36% 0rem;
                z-index: 2;
                @include desktop2nd() {
                    display: none;
                }
            }



            .woman-pix {
                position: relative;
                width: 100%;
                height: auto;
                z-index: 4;

            }

            

            .h-quarters {
                font-family: 'Playfair Display';
                font-style: normal;
                font-weight: 700;
                font-size: 46px;
                
                // line-height: 60px;
                /* identical to box height, or 131% */

                // display: flex;
                // align-items: center;

                color: #15518A;

                @include mobile() {
                    font-size: 25px;
                    
                }
            }


            .p-quarters {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                margin-top: -1.8rem;
                // line-height: 60px;
                /* identical to box height, or 376% */

                // display: flex;
                // align-items: center;

                color: #000000;
                @include mobile() {
                    margin-top: -.9rem;
                    
                }
            }
        }
    }



    .word-con {

        @include desktop2nd() {
            display: flex;
            justify-content: center;
            align-items: center;
        }
            
        .content-D {
            position: relative;
            z-index: 1;
            margin-top: .5rem;

            @include mobile() {
                margin-right: 1.8rem ;
            }

            .redder2 {
                position: relative;
                background-color: #E32E3F;
                width: 58%;
                height: auto;
                color: transparent;
                padding: 15% 0rem;
                z-index: 2;
                margin-right: -1rem;
                
                @include desktop2nd() {
                    display: none;
                }   

            }

            .why-b {
                font-family: 'Playfair Display';
                font-style: normal;
                font-weight: 700;
                font-size: 54px;
                // line-height: 53px;
                /* identical to box height, or 97% */
    
                // display: flex;
                // align-items: center;
    
                color: #15518A;
    
                @include mobile() {
                    font-size: 25px;
                    margin-top: -1rem;
                    
                }
    
            }
    
    
            .p-bird {
                width: fit-content;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 22.231px;
                line-height: 31px;
                margin-top: -1.7rem;
                letter-spacing: .03rem;
                /* or 140% */
    
                display: flex;
                align-items: center;
    
                color: #000000;
                @include mobile() {
                    margin-top: -.7rem;
                font-size: 1.2em;
                    line-height: 1.57;
                }
            }
            .station-con {
                margin-bottom: -8.9rem;
                margin-top: 2.5625rem;

                @include desktop2nd() {
                    margin-bottom: .4rem;
                }
                .station-pic {
                    position: relative;
                    width: 100%;
                    height: auto;
                    z-index: 4;

                }
            }
        }

        
    }
}


