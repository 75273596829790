.careers-container {
    background-color: #ECF2FF;
    padding: 3.625rem 5.3rem 0rem 5.3rem;

    @include desktop2nd() {
        padding: 3.625rem 5.25rem 0rem;

    }

    @include mobile() {
        padding: 1.25rem 3rem 0rem;
    }

.header-holder {
     display: flex;
     justify-content: center;
     margin-bottom: 1.8rem;
     @include mobile() {
        margin-bottom: 0rem;
    }
    .careers-header {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 700;
        font-size: 3.625rem;
        line-height: 3.5625rem;
        color: #15518A;
        @include mobile() {
            font-size: 1.5625rem;
            margin-bottom: 1.25rem;
        }
    }
}
    

    .row {
        background-color: #ffffff;
        padding: 8.794375rem 2.9375rem 6.794375rem;
        border-top-left-radius: 1.875rem;
        border-top-right-radius: 1.875rem;

        @include smallmobile3rd() {
            display: flex;
            flex-direction: column; 
            padding-left: .9375rem ;
            padding-right: .9375rem;
            }
       
        

        @include mobile() {
            padding-top: 3.5rem;
            padding-bottom: 3.5rem;
        }
        @include smallmobile2nd() {
            padding: 2.5rem .5rem 3.5rem;

        }


        .col-left {
            @include smallmobile3rd() {
                display: flex;
                flex-direction: column; 

            }

            .apply-div {
               display: flex;
               flex-direction: column;
               gap: .975rem;
               margin-bottom: 2.155rem;

              .two-holder {
                margin-bottom: -2.52185rem;
                @include smallmobile2nd() {
                    display: flex;
                    flex-direction: column;
                    gap:.675rem;
                    justify-content: center;
                    align-items: center; 
                     }
               

                  .apply-header {
                    font-family: 'Playfair Display';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 3rem;
                    line-height: 3.75rem;
                    color: #15518A;

                    @include mobile() {
                        font-size: 1.5625rem;
                    }

                    @include smallmobile2nd() {
                        line-height: 1.75rem;
                        text-align: center;
                            
                        }
                  }
           
                  .liner-div {
           
                   .liner {
                    margin-left: 4.5rem;
                    margin-top: -3.5rem;
                    max-width: 8.3125rem;
                    height: auto;
                      
                    @include mobile() {
                        max-width: 4rem;
                        margin-left: 2.2rem;

                    }
                    
                    @include smallmobile2nd() {
                        margin-left: 1.7rem;
                         }
                   }
                  }
             }
      
             
            }
      
            .submit {
              color: #ffffff;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: .80240625rem 1.97515rem;
              background: #15518A;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: 1rem;
              line-height: 1.5rem;
              font-feature-settings: 'salt' on, 'liga' off;
              color: #FFFFFF;
              border-radius: .370340625rem;
              cursor: pointer;
      
              &:hover {
                opacity: .9;
              }

              @include smallmobile3rd() {
                align-self: flex-start;
              }

              @include NewMobile() {
                align-self: center;
              }
            }
            
          }
      
      
      
          .col-right {
      
      
             .customer-div {
      
                @include mobile() {
                    margin-top:2rem;
                }
              .customer {
                  width: 100%;
                  height: auto;
                  border: solid .62rem #E32E3F;

                  @include mobile() {
                    border: solid .42rem #E32E3F;
                }
              }
             }
          }
    }
}