.careerspage {
  background: $pagecolor;
  min-height: 100vh;
  max-height: 100%;

  .careerspage-main {
    padding: 1.5rem 4rem;

    @include mobile() {
      padding: 1rem 1rem;
    }

    .careerscard-holder {
      width: 80%;
      margin: 0 auto;

      @include mobile() {
        width: 100%;
      }
    }
  }
}