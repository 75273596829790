.template{
    padding: 10em 8em;
    font-family: sans-serif;
    *{
        font-weight: 600
    }
    p, h1, h2{
        margin: 0
    }
    .x-row{
        display: flex;
        flex-direction: row; 
    }
    .x-right{
        display: flex;
        justify-content: flex-end;
    }
    .x-space-between{
        justify-content: space-between;
    }
    .x-flex-end{
        align-items: flex-end;
    }
    .x-border{
        border: 2px solid #000;
    }
    .x-border-top{
        border-top: 2px solid #000;
    }
    .x-border-bottom{
        border-bottom: 2px solid #000;
    }
    .x-border-right{
        border-right: 2px solid #000;
    }
    .x-border-left{
        border-left: 2px solid #000;
    }
    .x-width-50{
        width: 50%;
    }
    .x-width-40{
        width: 40%;
    }
    .x-width-25{
        width: 25.3%;
    }
    .x-table{
        .x-col{
            padding: 0 1em;
            width: 50%;
            // border-left: 1px solid #000;
        }
    }

    .x-addr{
        padding: .5em;
        font-size: 1.3em;
        font-weight: bold;
    }
    .x-billToAddr, .x-shipToAddr{
        font-size: 1.1em;
        padding:  .5em .5em 5em
    }
    .x-second{
        margin: 1em 0
    }
    .x-billTo{
        padding: 1em .5em ;
        font-size: 1.2em;
        font-weight: bold;
    }
    .x-shipTo{
        padding: .3em .5em;
        font-size: 1.2em;
        font-weight: bold;
    }
   .x-list-box{
        margin-top: -2em;
        .x-list-table{
            border: 2px solid #000;
            // display: flex;
            // min-height: 500px;
            .x-head{
                text-align: center;
                font-weight: bold;
            }
            .x-content{
                padding: 2em;
                p{
                    margin-bottom: .5em;
                }
            }
    }
   }
   .x-box-footer{
    .x-left-grid{
        margin-top: 10px;

    .x-left-box{
        padding: .5em 1em;
        font-weight: 600;
        font-size: 1.1em;
        font-style: italic;
        height: 65.3px;
        display: flex;
        align-items: center;
    }
    }
    .x-right-box{
        // min-height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
        height: 70px;
        span{
            font-size: 1.1em;
        }
       
    }
   }
   .signature{
    display: flex;
    flex-direction: column;
    
    width: 30%;
    align-items: center;
    img{
        margin-top: 6em;
        width: 200px;
    }
    p{
        font-size: 1.2em;
       
    }
    .x-divider{
        width: 70%;
   height: 2px;
   background-color: #000;

    }
   }
}