.uploadform {
  .ant-form-item-label {
    padding: 0;
  }

  input {
    height: 2.5rem;
    border-radius: 8px;
  }

  .submit-btn {
    // border-radius: 40px;
    background: $purple;
    color: $white;
    padding: 0.8rem 3rem 2.2rem;
    // height: 2.5rem;
    outline: none !important;
    border-color: $white !important;

    &:hover,
    &:focus,
    &:active {
      outline: none !important;
      border-color: $white !important;
    }
  }
}