/* * {
    box-sizing: border-box;
  } */
body {

    overflow-x:hidden;
}

.x-orange {
  background: #F2994A !important;
  color: #fff !important
}

.x-grey {
  background: grey !important;
  color: #fff !important
}
