.careerdetails {
  background: $pagecolor;
  min-height: 100vh;
  max-height: 100%;

  .careerdetails-main {
    padding: 1.5rem 4rem;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-column-gap: 1.5rem;
    position: relative;

    @include mobile() {
      grid-template-columns: 100%;
    }

    .upload-form {
      // position: fixed;
      width: 100%;
      // right: 0;
      padding: 1rem 1rem 0;
      background: $white;
      box-shadow: $box-shadow;
      margin-right: 4rem;
      border-radius: 8px;

      @include mobile() {
        margin-top: 1rem;
        margin-right: 0;
        position: relative;
        width: 100%;
      }

      .title {
        color: $black;
        font-size: 20px;
        font-weight: bolder;
        margin-bottom: 0;
        font-family: 'Avenir-Medium', sans-serif !important;
        text-align: center;
        margin-bottom: 1rem;
      }
    }


    @include mobile() {
      padding: 1rem 1rem;
    }

    .info {
      background-color: $white;
      padding: 2em;
      .title {
        text-transform: capitalize;
        color: $black;
        font-size: 30px;
        font-weight: bolder;
        margin-bottom: 0;
        font-family: 'Avenir-Bold', sans-serif !important;
      }

      .footnote {
        text-transform: capitalize;
        color: $titlePurple;
        font-weight: bolder;
        margin-bottom: 0;
        font-family: 'Avenir-Bold', sans-serif !important;
      }

      .job-details {
        margin-top: 2rem;

        .heading {
          color: $fontgrey;
          border-bottom: 1px solid $borderColor;
          font-family: 'Avenir-Bold', sans-serif !important;

          &.below {
            margin-top: 2rem;
          }

          &.sub-heafing {
            border-bottom: 0px solid $fontgrey;
            text-decoration: underline;
          }
        }

        .question {
          font-family: 'Avenir-Bold', sans-serif !important;
        }

        .list-holder {
          margin-top: 1.5rem;
        }
      }
    }
  }
}