.hamburger {
    display: flex;
    width: 2rem;
    height: 2rem;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 10;
    padding: .06rem;
    border: solid .1rem #e8ebef;
    border-radius: 0.425rem;

    


    .burger {
        width: 1.5rem;
        height: 0.25rem;
        border-radius: 0.625rem;
        background-color: #A9B4C0;
        transform-origin: 0.0625rem;
        transition: all 0.3s linear;
    }
}
