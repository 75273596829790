.x-signatures{

    .section-top {
        display: flex;
        justify-content: space-between;
        .action-holder {
          display: flex;
          button {
            &:first-child {
              margin-right: 0.5rem;
            }
          }
        }
      }
    
      .title-head {
        font-size: 25px;
        font-family: "Avenir-Medium", sans-serif !important;
        align-items: center;
        display: flex;
        button{
          margin-right: 1em;
        }
      }
    // h1{
    //   font-size: 1.5em;
    // }
    .x-box{
    box-shadow: $extrabox-shadow;
    background: $white;
    cursor: pointer;
    margin: 2em;
    padding: 3em;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
    flex-direction: column;
    }
    .x-item{
      box-shadow: $extrabox-shadow;
      margin: 1em 0;
      padding:2em  ;
      img{
        width: 100%;
      }
      p{
        margin-left: 2em;
        margin-bottom: 0;
        span{
          text-transform: capitalize;
          font-weight: 500;
          font-size: 1.2em;
        }
      }
    }
}