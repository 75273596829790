.xabout {
  .about-main {
    padding: 0rem 6rem;
    .ant-row{
      @include mobile() {
       flex-direction: column-reverse !important;
      }
    }
    @include mobile() {
      padding: 0rem 1rem;
    }

    .banner-content {
      padding-bottom: 2em;
      @include mobile() {
        text-align: center;
      }

      .title {
        font-size: 38px;
        // margin-bottom: 0.5rem;
        color: $titlePurple;
        line-height: 73px;
        font-family: 'Avenir-Bold', sans-serif !important;
        padding: 3rem 0rem 0;
      @include mobile() {
        line-height: 40px;
        font-size: 1.2em;
        padding: 0.5em;
        margin-bottom: 0;
        margin-top: 1em;
      }
      }

      .footnote {
        font-size: 1em;
        color: $fontgrey;
        margin-bottom: 1.5rem;
        font-family: 'Avenir-Medium', sans-serif !important;
    
      }

      .careers-btn {
        // border-radius: 40px;
        background: $purple;
        color: $white;
        padding: 0.5rem 3rem 2.2rem;
        height: 2.5rem;
        outline: none !important;
        border-color: $white !important;
        font-size: 1.2em;
        &:hover,
        &:focus,
        &:active {
          outline: none !important;
          border-color: $white !important;
        }
      }
    }

    .img-holder {
      img {
        width: 100%;
        // height: 35rem;

        @include mobile() {
          // height: 10rem;
        }
      }
    }
  }

  .x-banner {
    padding: 10em 6em;
    // background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.4)),
    //   url("https://res.cloudinary.com/afrikal/image/upload/v1614412010/bedroom_bydwyx.png")
    //     no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    h1 {
      line-height: 50px;
      font-family: "Avenir-Bold", sans-serif !important;
      letter-spacing: 5px;
      font-size: 3em;
      color: #fff;
      margin-top: 1.2em;
      @include mobile() {
        font-size: 2em;
        line-height: 40px;
        margin-top: 1.5em;
      }
    }

    p {
      font-family: "Avenir-Medium", sans-serif !important;
      font-size: 1.2em;
      margin: 1em 0 2em;
      color: #fff;
      @include mobile() {
        font-size: 1.1em;
      }
    }
    @include mobile() {
      padding: 6em 2em 4em; 
    }
  }
  .pac-target-input{

    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 1.5713;

    &:focus{
      border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    // -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    // box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    }
  }
  .x-loc-card{
    background: $white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        margin: 1em 0;
    padding: 2em;
    h3{
      color: $titlePurple;
      font-family: 'Avenir-Bold', sans-serif !important;
      @include mobile(){
        font-size: 1em;
      }

    }
  }
}