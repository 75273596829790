
    
    .light-blue {
        width: auto;
        background-color: #ECF2FF;
        padding: 0rem 5.25rem 0rem 5.35rem;

        @include desktop2nd() {
            padding: 0rem 5.4rem;

        }
        @include mobile() {
            padding: 0rem 2.7rem;
            
        }

    
        .body-h {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 700;
            font-size: 3.625rem;
            line-height: 3.5625rem;
            color: #15518A;
            padding: 4rem 0rem;
            @include mobile() {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.5625rem;
                padding: 1rem 0rem;
                
            }
    
        }
    
    
        .white-back {
            background-color: #FFFFFF;
            padding: 3.5625rem 2.875rem;
            border-top-right-radius: 1.3rem;
            border-top-left-radius: 1.3rem;
                @include smallmobile3rd() {
                  padding: .5625rem 0rem;
                display: flex;
                    flex-direction: column;
                    
                }
                .left-col {
                @include smallmobile2nd() {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .r-div {

               
                    .locators {
                        padding-top: 3.696975rem;
                        width: 22.0625rem;
                      @include mobile() {
                        padding-top: 0rem;
                        width: auto;
                      }
                      @include smallmobile3rd() {
                        padding-top: 0rem;
                      }
                       
                    }
                    .pin-pic {
                        width: 100%;
                        height: auto;
                    } 
                    .enter-h {
                        font-family: 'Playfair Display';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 2.875rem;
                        line-height: 3.75rem;
                        color: #15518A;
    
                        @include mobile() {
                            font-size: 1.5625rem;
                            text-align: center;
                        }
    
                        @include smallmobile2nd() {
                            margin-top: 1.5rem;
                            line-height: 2.2rem;
                        }
                    }
    
                    .search-p {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1rem;
                        line-height: 3.75rem;
                        color: #000000;
                        margin-top: -1.6rem;
                        margin-bottom: -.3rem;
                        @include mobile() {
                            text-align: center;
                        }
    
                        @include smallmobile2nd() {
                            margin-top: 1.5rem;
                            margin-bottom: 1.5rem;
                            line-height: 1.5rem;
                        }
                        
                    }
    
                   
                }


                .search-but {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: .8025rem 1.75rem;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    font-feature-settings: 'salt' on, 'liga' off;
                    color: #FFFFFF;
                    background: #15518A;
                    border-radius: .370340625rem;
                    margin-top: 2rem;
                    cursor: pointer;
                    &:hover { opacity: .9;}
    
    
                }
            }

            

          
            
            
           .right-col {

            @include mobile() {
                margin-top: 2rem;
            }
            @include desktop2nd() {
                display: flex;
                align-self: center;
                justify-self: center;
            }
            
            .geo-map {
                width: 100%;
                height: auto;
            }
           }

            
        }
    }


