.x-footer{
   background: #fbd336;
   padding: 4em 10em;
   color: #fff;
   margin-top: 2em;
   @include mobile() {
    padding: 3em 2em;
  }

  img{
    width: 100px;
  }
  .x-socials a{
      color: #fff;
      font-size: 1.5em
  }
   h2{
       color: #fff;
       font-family: "Avenir-Medium";
       font-size: 1.7em;
       @include mobile() {
       font-size: 1.2em;
      }
   }
   .x-borderbottom{
       border-bottom: 1px solid #fff;
       padding: 0 0 2em ;
       margin-bottom: 2em;
   }
   .x-logo{
       
       img{
           padding-right: 1em;
           margin-left: -1em;
       }
       span{
           font-size: 1.3em;
       font-family: "Avenir-Medium";
       @include mobile() {
        font-size: 1.2em;
       }
    }
   }
   .x-text{
       padding-top: 1em;
       .x-grey{
           color: #aaa;
           padding-right: 2em;
       }
   }
   .x-socials{
       display: flex;
       margin: 2em 0;
       a {
           padding-right: 2em;
       }
   }
   .right-links{
    @include mobile() {
       padding-top: 2em;
       }
       a{
           color: #aaa;
       }
   }
}