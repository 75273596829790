.dashboardmain {
  .big-title {
    font-size: 30px;
    font-weight: 900;
    font-family: 'Avenir-Bold', sans-serif !important;
    margin-bottom: 0.5rem;
  }

  .content-to-below {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.5rem;

    p {
      font-size: 16px;
      font-family: 'Avenir-Medium', sans-serif !important;
    }
  }
}