.shelvers {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1679907809/birdies/StoreAndOverlay_uzebbt.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 13.125rem;

    .center-header {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 700;
        font-size: 3.625rem;
        line-height: 3.5625rem;
        color: #FFFFFF;
        @include mobile() {
            font-size: 1.25rem;
        }
    }
}

.holder-row {
background-color: #ffffff;
padding: 2.7525rem  3.19375rem 18.539375rem;

@include smallmobile2nd() {
    padding: 2.7225rem 3rem 7rem;
}

.holder-col{
padding-bottom: 1 rem;
    .product-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #F6F9FF;
        border: 0.05rem solid #15518ABD;


        .product-card-image {
           margin-top: .7rem;
           padding: 1.903125rem 1.9rem;
           max-width: 23.759375rem;
           height: auto;
           background-color: #ffffff;

           @include desktop2nd () {
            padding: 2.903125rem 4rem;
            max-width: 23.759375rem;
            height: auto;

           }

           @include mobile () {
            padding: 2.903125rem 0rem 2rem;
            max-width: 20.759375rem;
           }

           @include smallmobile2nd () {
            padding: 2.903125rem 0rem 2rem;
            max-width: 15.959375rem;
           }

        }

        .product-card-content {
            display: flex;
            flex-direction: column;
            gap: .46125rem;
            justify-content: center;
            align-items: center;
            padding: 1.909375rem 0rem 1.165625rem;
            
            .title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 1.80866875rem;
                line-height: 2rem;
                color: #15518A;
            }

            .description {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 1.3062625rem;
                line-height: 2rem;
                color: #000000;
            }
        }
    }
}

}

