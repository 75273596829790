.login {
  background: #f7f7f7;
  // min-height: 100vh;
  height: 100%;
  position: absolute;
  width: 100%;
  .form-holder {
    display: flex;
    justify-content: center;
    padding: .5rem 1rem;

    .login-form {
      margin-top: 7%;
      background: $white;
      border-radius: 8px;
      width: 35%;
      box-shadow: $box-shadow;
      padding: 1.5rem;
      text-align: center;
      @include mobile() {
        width: 100%;
      }

      .title {
        font-family: 'Avenir-Medium', sans-serif !important;
      }

      .ant-form-item-label {
        padding: 0;
      }

      input {
        height: 2.5rem;
        border-radius: 8px;
      }

      .password-input {
        height: 2.6rem;
        border-radius: 8px;

        input {
          height: 2rem;
          border-radius: unset;
        }
      }

      .submit-btn {
        // border-radius: 40px;
        background: $purple;
        color: $white;
        padding: 0.8rem 3rem 2.2rem;
        height: 2.5rem;
        outline: none !important;
        border-color: $white !important;

        &:hover,
        &:focus,
        &:active {
          outline: none !important;
          border-color: $white !important;
        }
      }
    }
  }
}