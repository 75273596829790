.HomeContainer {
    margin: 0;
    

    .family {
        background-color: #F6F9FF;
        padding: 4.0625rem 0rem 5.155rem 6.5625rem;
        @include desktop2nd() {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 1rem;
            }

            @include desktop2nd() {
                padding: 1.0625rem 1.5625rem;
               
               }

        .l-aside {
             
            padding-top: 5.85375rem;
            @include mobile() {
                display: flex;
            }
            @include desktop2nd() {
                padding-top: 2.0625rem;
               
               }
               @include mobile() {
                padding-top: .0625rem;
                    
               }
        
            .l-div {
            
                .combined {

                    @include mobile() {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    .h-family {

                        font-family: 'Playfair Display';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 5.125rem;
                        line-height: 5.6875rem;
                        letter-spacing: .010959125rem;
                        color: #15518A;
    
                        @include mobile() {
                            font-size: 1.5625rem;
                            line-height: 2.6875rem;
                            text-align: center;
                            margin-bottom: -1.21rem;
                            margin-right: 4rem;
                        }
                    }
        
        
                    .red-line {
                        @include mobile() {
                            margin-top: .6rem;
                            max-width: 8rem;
                            margin-right: 10rem;

                        }
    
                        @include smallmobile3rd() {
                            max-width: 5rem;
                        } 
    
                        @include smallmobile2nd() {
                            max-width: 3rem;
                            margin-right: 8.5rem;
                        } 
                            .red-lina {
                            width: 100%;
                            height: auto;
                        }
                    }
    
                }
                    
    
                .aboout-p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 32px;
                    margin-top: 0.66rem;
                    margin-bottom: 0.66rem;
                    letter-spacing: 0.010959125rem;
                    color: #000000;
                    @include mobile() {
                        font-size: 1.2em;
                        text-align: center;
                        
                    }
                }
            } 
        }

        
        .r-side {
            
            .family-image {
                width: 100%;
                height: auto;
                border:.625rem solid red;
                border-right: 0;
                @include desktop2nd() {
                    border:.425rem solid red;
                    border-right: .425rem solid red;

                }
            }
        }
    }

   

    .logos-div {
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;

        .logos-header {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.9375rem;
            color: #242424;
            text-align: center;
            padding-top: 1.8125rem;

            @include mobile() {
                font-size: .7rem;
                line-height: 3rem;
                padding-top: 1rem;
            }
        }
        .image-img-div {
                @include desktop2nd() {}

            .image-img {
                width: 40%;
                display: block;
                margin-left: auto;
                margin-right: auto;

                @include desktop2nd() {
                    width: 15%;
                    display: block;
                }
            }
        }

        .for-top {
            @include desktop2nd() {
                margin-top: 3rem;
            }
        }
        .fortop-768 {
            @include mobile() {
                margin-top: 3rem;
            }
        }
        
    }



    .offer-div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F6F9FF;
        padding: 3.8125rem 0rem 1.5rem;

        .offer {
            width: fit-content;
            color: #15518A;
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 700;
            font-size: 3.625rem;
            line-height: 4.4375rem;

            @include mobile() {
                font-size: 2em;
                line-height: unset;
            }

        }
    }



    .choppy {
        position: relative;
        display: grid;
        grid-template-rows: repeat(2, 1fr);

        @include mobile() {
            display: grid;
            grid-template-rows: repeat(2, 1fr);

        }

        .pizza_combo {
            display: grid;
            grid-template-areas: "half-pizza full-pizza";
            grid-template-columns: repeat(2, 1fr);
            

            .half_pizza {
                background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1678900897/birdies/half-pizza_new_gzunm9.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                object-fit: cover;
                height: 45.875rem;
                position: relative;
                grid-area: half-pizza;
                width: 100%;
                height: auto;
                @include mobile(){
                    height: 25rem;
                }
                .p-pizza {
                    width: fit-content;
                    float: right;
                    margin-right: -4.3rem;
                    padding-top: 37.685rem;
                    font-family: 'Playfair Display';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 3.625rem;
                    line-height: 4.4375rem;
                    display: flex;
                    align-items: center;
                    color: #FFFFFF;
                    @include mobile(){
                        padding-top: 19rem;
                        font-size: 2rem;
                        margin-right: -2.4rem;

                    }
                }

            }



            .full_pizza {
                background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1678900912/birdies/full-pizza_new_qdimrs.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                object-fit: cover;
                height: 45.875rem;
                grid-area: full-pizza;
                @include mobile(){
                    height: 25rem;
                }
               
            }
        }


        .subcrunchy {
            display: grid;
            grid-template-areas: "subway crunchy";
            grid-template-columns: repeat(2, 1fr);

            .subway {
                display: flex;
                justify-content: center;
                align-items: end;
                background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1678890055/birdies/krispy_new_b6mmzz.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                object-fit: cover;
                height: 45.875rem;
                grid-area: subway;
                @include mobile(){
                    height: 25rem;
                }


                .p-sub {
                    font-family: 'Playfair Display';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 3.25rem;
                    line-height: 4.4375rem;
                    display: flex;
                    align-items: center;
                    color: #FFFFFF;
                    @include mobile(){
                        font-size: 2em;
                        line-height: 1.875rem;
                        text-align: center;
                      }
                }
            }

            .krunchy {
                display: flex;
                justify-content: center;
                align-items: end;
                background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1678890071/birdies/krunchy_new_ugjmdn.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                object-fit: cover;
                height: 45.875rem;
                grid-area: crunchy;
                @include mobile(){
                    height: 25rem;
                }



                .p-sub {
                    font-family: 'Playfair Display';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 3.25rem;
                    line-height: 4.4375rem;
                    display: flex;
                    align-items: center;
                    color: #FFFFFF;
                    @include mobile(){
                      font-size: 2em;
                      line-height: 1.875rem;
                      text-align: center;
                    }
                }
            }
        }


        .peso {
            display: none;
            justify-content: start;
            align-items: center;
            padding-left: 5.5625rem;
            background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714707/birdies/Peso_bzfbcx.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            object-fit: cover;
            height: 45.875rem;
            @include mobile() {
              height: 20em;
              padding: 2em;
            }


            .p-subp {
                width: 29.625rem;
                font-family: 'Playfair Display';
                font-style: normal;
                font-weight: 700;
                font-size: 4rem;
                line-height: 4.4375rem;
                display: flex;
                align-items: center;
                color: #FFFFFF;
                @include mobile() {
                    width: unset;
                    font-size: 2em;
                    line-height: unset;
                    text-align: center;
                 }
            }
        }
    }




    .restaurant {
        display: grid;
        grid-template-rows: 2;


        .coffeWoman {
            padding: 10.125rem 0rem 22.375rem 0rem;

            background-image: "url(/coffeWoman.png)";
            background-repeat: no-repeat;
            background-size: 100% 100%;
            object-fit: cover;
            @include mobile(){
                padding: 8em 0;
            }
            .blueAside {
                background-color: #15518A;
                width: 27.375rem;
                border-radius: 0rem 2rem 2rem 0rem;
                @include mobile(){
                    width: 80%;
                }

                .h-coffee {
                    font-family: 'Playfair Display';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 3.25rem;
                    line-height: 4.9375rem;
                    color: #F6F9FF;
                    @include  mobile(){
                        font-size: 2em;
                        line-height: unset; 
                        padding: 1em 1em;
                    }
                }

            }
        }



        .threedivs {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            height: 30.583125rem;
            @include mobile(){
                display: none;
            }
            .icecream {
                background-image: "url(/icecream.png)";
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                object-fit: cover;
            }

            .coffe {
                background-image: "url(/coffee.png)";
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                object-fit: cover;
            }


            .coffeJar {
                background-image: "url(/coffeJar.png)";
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                object-fit: cover;
            }



        }
    }





    .services {
        position: relative;
        margin-top: .4rem;
        margin-bottom: .4rem;

        .male-cook, .shelves, .gas_staion-pic, .female-baker-pix {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .male-cook {
            background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1680178094/birdies/cook-guy_e2a5xq.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 27.3125rem;
            
        }

        .shelves {
            background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1680178144/birdies/shelvers_czmfqv.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 27.3125rem;
        }

        .gas_staion-pic {
            background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1680178072/birdies/birdies-gas_zqgj7g.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 27.3125rem;
        }
        .female-baker-pix{
            background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1680178070/birdies/Baker_uk5otq.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 27.3125rem;
        }
        .image-center-h1 {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 700;
            font-size: 3.84580625rem;
            line-height: 5.125rem;
            text-align: center;
            color: #FFFFFF;
            @include mobile() {
                font-size: 2rem;
                line-height: 2.6875rem;

            }
            
        }

       

    }



    .debitCard {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3.1875rem;
        padding-bottom: 3.13625rem;
        background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714134/birdies/debitCard_mbrgym.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        
       

        .card-div {
            display: grid;
            grid-template-rows: 2;
            gap: 30.625rem;
            justify-items: center;
            @include mobile(){
                gap: 16rem
            }
            .h-card {
                width: fit-content;
                font-family: 'Playfair Display';
                font-style: normal;
                font-weight: 700;
                font-size: 3.75rem;
                line-height: 4.4375rem;
                color: #15518A;
                @include mobile(){
                    font-size: 2em;
                    margin: 0 2em;
                    line-height: unset;
                }
            }


            .btn-a {
                width: fit-content;
                border: none;
            }

            .btn-card {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1.375rem 2.09375rem;
                background: #15518A;
                border-radius: .370340625rem;
                color: #FFFFFF;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                text-align: center;
                font-feature-settings: 'salt' on, 'liga' off;

                &:hover {
                    opacity: .9;
                }
            }

        }
    }




    .userDetail {
        background-color: #F6F9FF;
        padding: 5.125rem 8.625rem 6rem 5.925rem;
        @include mobile() {
            padding:2.5rem 3rem;
        }
        @include smallmobile2nd() {
            padding: 2.5rem .5rem;
        }

        .userInputs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            .userInput-div {
                
                @include mobile() {
                    text-align: center;
                }
                
                .h1-holder {
                    @include mobile() {
                        display: flex;
                        justify-content: center;
                    }
                    .h-input {
                        width: fit-content;
                        font-family: 'Playfair Display';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 3rem;
                        line-height: 3.75rem;
                        color: #15518A;
                        @include mobile(){
                            text-align: center;
                            line-height: 2.6875rem;
                            font-size: 2em;
                        }
                    }
                }
                
    
                .all-input {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
    
                    .i-input {
                        color: #6B737A80;
                        border: 0.0625rem solid #A9B4C0;
                        box-sizing: border-box;
                        border-radius: 0.25rem;
                        padding: 0.75rem 5.6875rem 0.75rem 1.0625rem;
    
                        &::placeholder {
                            color: #A9B4C0;
                        }
    
                        &:focus {
                            outline: none;
                            border: .0625rem solid #A9B4C0;
                        }
                    }
    
                    .r-size {
                        resize: none;
                    }
                }
    
                .btn-send {
                    margin-top: 3.04125rem;
                    padding: .80240625rem 1.97515rem 2.40240625rem;
                    color: #FFFFFF;
                    background: #15518A;
                    border-radius: 0.370340625rem;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    text-align: center;
                    font-feature-settings: 'salt' on, 'liga' off;
                    &:hover {
                        opacity: .9;
                    }
                    
                }
    
            }
        }

        .a-image {
             display: flex;
             justify-content: center;
             align-items: center;
           

            .google-map-div {
                width: 100%;
                height: 20.9825rem;
                background-image: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714322/birdies/map_scmwjf.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                margin-top: -1rem;
                @include mobile() {
                    margin-top: 3rem;
                    margin-right: 3.5rem;
                
                }
                @include smallmobile3rd() {
                    margin-right: 2rem;
                }
                
            }
            
            
            // @include mobile(){
            //     display: none;
            // }
        }


    }


}